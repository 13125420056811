<template>
  <div
    class="
      d-flex
      flex-column flex-column-fluid
      bgi-position-y-bottom
      position-x-center
      bgi-no-repeat bgi-size-contain bgi-attachment-fixed
    "
  >
    <!--begin::Content-->
    <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
      <!--begin::Logo-->
      <!-- <a href="#" class="mb-12">
        <img alt="Logo" src="other/png/logo.png" class="" />
      </a> -->
      <!--end::Logo-->

      <router-view class="shadow-lg"></router-view>
    </div>
    <!--end::Content-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "auth",
  components: {},
  setup() {
    const router = useRouter();

    const store = useStore();

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-login");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-login");

      // Check User is Loggin or not
      if (store.getters.isUserAuthenticated) {
        router.push({ name: "resume" });
      }
    });
  },
});
</script>

<style>
.bg-login {
  background-image: url("../../../public/media/patterns/content-bg.jpg") !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
</style>
